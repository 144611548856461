import { Injectable, WritableSignal, computed, inject, signal } from '@angular/core';
import { CustomUserClaims, OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { OktaAuthStateService } from '@okta/okta-angular';
import { EMPTY, from, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/user.model';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})

export class AuthService {
    public displayName = computed(() => this.user()?.name || null);
    private readonly authService = inject(OktaAuthStateService);
    private readonly http = inject(HttpClient);

    private user: WritableSignal<UserClaims | null> = signal(null);
    private userInfo!: User;
    private readonly oktaAuth = new OktaAuth(environment.okta);


    getSubordinates(): Observable<number[]> {
        return this.getEmployeeId().pipe(
            switchMap((id) => this.userInfo ?
                of(this.userInfo.subordinates)
                : this.http.get<User>(`${environment.urls.api}user/${id}/info`).pipe(
                    tap(data => {
                        this.userInfo = data;
                    }),
                    map(m => m.subordinates),
                ))
        );
    }


    isSupervisor(): Observable<boolean> {
        return this.getEmployeeId().pipe(
            switchMap((id) => this.userInfo ?
                of(this.userInfo.subordinates.length > 0)
                : this.http.get<User>(`${environment.urls.api}user/${id}/info`).pipe(
                    tap(data => {
                        this.userInfo = data;
                    }),
                    map(m => m.subordinates.length > 0),
                ))
        );
    }


    getUser(): Observable<UserClaims<CustomUserClaims>> {
        return this.authService.authState$.pipe(
            switchMap(
                (state) => from(state.isAuthenticated ? this.oktaAuth.getUser() : EMPTY)
                    .pipe(
                        map(this.getAvatar),
                        tap(user => this.user.set(user)),
                    )
            ),
            shareReplay(),
        )
    }


    logout(): Observable<boolean> {
        return from(this.oktaAuth.signOut());
    }


    isGroupMember(expectedGroups: string[]): Observable<boolean> {
        return this.getClaim('groups').pipe(
            map(m => {
                return m ? m.split(',').some(s => expectedGroups.includes(s)) : false;
            })
        )
    }


    isAdmin(): Observable<boolean> {
        const expectedGroups = [
            'IAM_DLAssessments_DEV_ADMIN',
            'IAM_DLAssessments_STG_ADMIN',
            'IAM_DLAssessments_PRD_ADMIN',
        ];
        return this.isGroupMember(expectedGroups);
    }


    getEmployeeId(): Observable<number> {
        return this.getClaim('EmployeeId').pipe(
            map(m => Number(m))
        );
    }


    getEmployeeEmail(): Observable<string> {
        return this.getClaim('email');
    }


    signout(): void {
        this.oktaAuth.signOut();
    }


    private getAvatar(user: UserClaims<CustomUserClaims>): UserClaims<CustomUserClaims> {
        const palette = ['#000000', '#939393', '#E3BC00', '#D47500', '#DC2A2A', '#00AA55', '#009FD4', '#B381B3'];
        const initials = user.name?.split(' ').map(n => n.substring(0, 1)).join('') || '';
        const charCodes = initials.split('').map(char => char.charCodeAt(0)).join('');
        const color = palette[parseInt(charCodes, 10) % palette.length];
        return { ...user, initials, color };
    }


    private getClaim(claim: string): Observable<string> {
        return this.user() ? of(this.user()![claim]?.toString())
            : this.getUser().pipe(
                map(user => user[claim]?.toString())
            );
    }
}
